<script setup lang="ts">
import type { PriceProps } from '~/components/Price.vue'
import type { ProductVariant } from '~/utils/crystallize/product'

const { variant, ...props } = defineProps<{ variant: ProductVariant } & Omit<PriceProps, 'price'>>()

const resolvedPrice = computed(() =>
  props.taxIncluded
    ? variant.priceVariants.default
    : getExVatPrice(
        variant.priceVariants.default,
        variant.components.effectiveVatPercent ?? 0,
      ),
)
</script>

<template>
  <Price v-bind="props" :price="resolvedPrice" />
</template>
