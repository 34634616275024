import type { Product, ProductVariant } from '~/utils/crystallize/product'

type Action = 'add' | 'remove'

interface MutationResult { action?: Action, updatedCart?: CartData }

interface MutationVariables { action: Action, cart: CartData }

type MaybeProduct = MaybeRef<ProductVariant | ProductCard | Product | undefined>

/**
 * Error class for errors that can be shown directly to the user.
 */
class SanitizedError extends Error {
  constructor(message: string, public fatal = false) {
    super(message)
    this.name = 'SanitizedError'
  }
}

/**
 * High level mutation for adding a product to or removing a product from the cart.
 *
 * This function will add, update or delete the product from the cart based on the provided action.
 *
 * @param product - The product to update.
 */
export function useUpdateCart(product: MaybeProduct) {
  const api = useCartApi()

  const toast = getToast()

  const queryClient = useQueryClient()

  const variant = useProductVariant(product)

  const { calculateDiff, calculateNewItems } = useCartDiff(variant)

  const { loggedIn } = useUserSession()

  return useMutation<MutationResult, Error, MutationVariables>({
    /**
     * Calculate the cart diff and perform the required API calls in sequence as to not lock the DB.
     */
    async mutationFn({ action, cart }) {
      const diff = calculateDiff(cart?.cartItems ?? [], action)

      let updatedCart: CartData
      if (diff.updated) {
        if (diff.updated.amount < 1) {
          throw new SanitizedError('Selskapet ditt har ingen brukslisenser.', true)
        }

        updatedCart = cart && {
          ...cart,
          cartItems: cart.cartItems.map(item => diff.updated?.uuid && item.uuid === diff.updated.uuid ? diff.updated : item),
        }
        await api.updateCartItem(diff.updated)
      }

      for (const uuid of diff.removed) {
        updatedCart = await api.deleteItemFromCart(uuid)
      }

      if (diff.added) {
        if (!diff.added.subscriptionPeriod || !diff.added.subscriptionPlan) {
          throw new SanitizedError(`Mangler abonnementsinformasjon for «${diff.added.productName}».`, true)
        }
        else if (diff.added.amount < 1) {
          throw new SanitizedError('Selskapet ditt har ingen brukslisenser.', true)
        }

        updatedCart = await api.addItemToCart(diff.added)
      }

      return { action, updatedCart }
    },

    /**
     * Update the cart optimistically before mutating so it feels instant to the user.
     */
    async onMutate({ action }) {
      // Cancel existing queries so they don't interfere with the optimistic update
      await queryClient.cancelQueries({ queryKey: CART_QUERY_KEY })

      if (!loggedIn.value) {
        // TODO: Add item to temp basket or similar
        throw await navigateTo('/logg-inn')
      }

      // Optimistically update the cart
      queryClient.setQueryData(CART_QUERY_KEY, (oldData: CartData) => {
        if (!oldData) {
          return oldData
        }

        return {
          ...oldData,
          cartItems: calculateNewItems(oldData.cartItems, action),
        }
      })
    },

    /**
     * Update the cart with the result of the mutation. If the mutation fails, revert the cart to its previous state.
     */
    onSettled(data, error, { cart }) {
      if (data?.updatedCart) {
        queryClient.setQueryData(CART_QUERY_KEY, data.updatedCart)
      }
      else {
        if (error && cart) {
          queryClient.setQueryData(CART_QUERY_KEY, cart)
        }

        void queryClient.invalidateQueries({ queryKey: CART_QUERY_KEY })
      }
    },

    onError(error) {
      if (error instanceof SanitizedError) {
        toast.error(error.message + (error.fatal ? ` Ta kontakt med kundeservice.` : ''))
      }
      else if (error) {
        toast.error('Vi klarte ikke å oppdatere handlekurven, prøv igjen senere. Om feilen vedvarer, ta kontakt med kundeservice.')
        throw error
      }
    },

    onSuccess(data) {
      const action = data.action === 'add' ? 'lagt i' : 'fjernet fra'
      toast.success(`«${variant.value?.name}» ble ${action} handlekurven`)
    },
  })
}

export function useAddToCart(product: MaybeProduct) {
  const update = useUpdateCart(product)

  const cart = useCart()

  return useMutation({
    mutationKey: ['addToCart'],
    mutationFn: () => update.mutateAsync({ action: 'add', cart: cart.data.value }),
  })
}

export function useRemoveFromCart(product: MaybeProduct) {
  const update = useUpdateCart(product)

  const cart = useCart()

  return useMutation({
    mutationKey: ['removeFromCart'],
    mutationFn: () => update.mutateAsync({ action: 'remove', cart: cart.data.value }),
  })
}
