import type { ProductVariant } from '~/utils/crystallize/product'

interface Diff {
  removed: Set<UUID>
  added?: CartItemDto
  updated?: CartItemDto
}

/**
 * CORE does not have an API for changing multiple cart items at once. We need to be able to do this, since a highlighted product in the cart should force removal of all other products and vice versa.
 *
 * This composable will calculate the diff between the current cart items and the new items that should be in the cart. The diff can then be used to both update the items optimistically, and trigger the correct API calls.
 *
 * @param variant - The variant to add or remove from the cart (which can then trigger other removals).
 */
export function useCartDiff(variant: MaybeRef<ProductVariant | undefined>) {
  const licenseInformation = useLicenseInformation()

  const highlighted = useHighlightedVariant()

  function isHighlighted(variant: MaybeRef<ProductVariant | CartItemDto | undefined | null>) {
    const value = unref(variant)
    if (!value) {
      return false
    }

    const sku = 'sku' in value ? value.sku : value.productId
    return sku === highlighted.value?.sku
  }

  function calculateDiff(cartItems: CartItemDto[], action: 'add' | 'remove'): Diff {
    const amount = unref(licenseInformation.count)

    const diff: Diff = {
      removed: new Set(),
    }

    const v = unref(variant)

    if (!v) {
      return diff
    }

    if (action === 'add') {
      const plan = v.subscriptionPlans?.[0]

      // Assume, for now, that we are adding a new item to the cart
      diff.added = {
        productId: v.sku,
        amount,
        productName: v.name,
        subscriptionPlan: plan?.identifier,
        subscriptionPeriod: plan?.periods?.[0]?.id,
      }
    }

    // Check existing cartItems for items that should be removed or if the add should be changed to an update
    for (const item of cartItems) {
      if (!item.uuid) {
        continue
      }

      if (
        (item.productId === v.sku && action === 'remove')
        // This special case ensures that highlighted products never share the cart with non-highlighted products
        || isHighlighted(variant) !== isHighlighted(item)
      ) {
        diff.removed.add(item.uuid)
      }
      else if (item.productId === v.sku && action === 'add') {
        delete diff.added

        if (item.amount !== amount) {
          diff.updated = {
            ...item,
            amount,
          }
        }
      }
    }

    return diff
  }

  function calculateNewItems(cartItems: CartItemDto[], action: 'add' | 'remove'): CartItemDto[] {
    const diff = calculateDiff(cartItems, action)
    return cartItems
      .filter(it => !it.uuid || !diff.removed.has(it.uuid))
      .map(it => (diff.updated?.uuid && diff.updated.uuid === it.uuid) ? diff.updated : it)
      .concat(diff.added ? [diff.added] : [])
  }

  return {
    calculateDiff,
    calculateNewItems,
  }
}
